import React from 'react';
import { H1, BodyLarge } from '../type';
import DividerHeader from '../divider-header';
import Container from '../container';
import SectionHeader from '../section-header';
import StrikeThrough from '../strike-through';
import styled from 'styled-components';
import * as vars from '../../styles/vars';

const About = () => {
  return (
    <>
      <Container id="about">
        <DividerHeader title="About" data-anim-in />
      </Container>
      <SectionHeader slim lessTopPadding zeroBottomPadding>
        <div data-anim-in>
          <H1 as="h3">Location</H1>
          <p>
            I'm based in <StrikeThrough>sunny,</StrikeThrough>{' '}
            <StrikeThrough>rainy,</StrikeThrough>{' '}
            <StrikeThrough>hot,</StrikeThrough>{' '}
            <StrikeThrough>cold,</StrikeThrough>{' '}
            <StrikeThrough>misty,</StrikeThrough>{' '}
            <StrikeThrough>windy,</StrikeThrough>
            <br /> spider filled &ndash; Melbourne, Australia
          </p>
        </div>
      </SectionHeader>
      <SectionHeader slim lessTopPadding>
        <div data-anim-in>
          <H1 as="h3">Skills/experience</H1>
          <p>
            I’ve been building (and occasionally designing) digital platforms
            &amp; products for a long time. I love what I do and I stay up to
            date with industry trends and build using the very latest techniques
            and practices.
          </p>
        </div>
      </SectionHeader>

      <Container slim>
        <SubHeading data-anim-in>Stuff I'm really good at:</SubHeading>

        <BigList>
          <li data-anim-in>React JS</li>
          <li data-anim-in>JavaScript</li>
          <li data-anim-in>Craft CMS</li>
          <li data-anim-in>Interaction design</li>
          <li data-anim-in>GSAP / AnimeJS</li>
          <li data-anim-in>CSS</li>
          <li data-anim-in>Accessibility</li>
          <li data-anim-in>Deep understanding of UX principles</li>
        </BigList>

        <SubHeading data-anim-in>Stuff I also have experience with:</SubHeading>

        <MediumList>
          <li data-anim-in>NextJS</li>
          <li data-anim-in>GatsbyJS</li>
          <li data-anim-in>Redux</li>
          <li data-anim-in>Recoil</li>
          <li data-anim-in>GraphQL / Apollo</li>
          <li data-anim-in>Jest</li>
        </MediumList>

        <SubHeading data-anim-in>Soft skills:</SubHeading>

        <BigList>
          <li data-anim-in>Always learning</li>
          <li data-anim-in>Motivated</li>
          <li data-anim-in>Organised</li>
          <li data-anim-in>Jolly nice chap</li>
        </BigList>

        <BodyLarge data-anim-in>
          <p>
            And of course I'm used to using all the tools of any modern setup:
            Git, Jira, Sketch etc and I also have extensive experience in
            various workflows: Agile, Waterfall etc, I am adaptable to however
            you like to roll.
          </p>
        </BodyLarge>
      </Container>
    </>
  );
};

const BigList = styled.ul`
  color: ${vars.colors.brand};
  font-family: ${vars.global.headingFontFamily};
  font-size: 42px;
  list-style: none;
  margin: 60px 0;

  ${vars.breakpoint.lg`
    margin: 60px 0 120px;
    font-size: 88px;
  `}

  li {
    line-height: 1;
    max-width: 900px;
    margin-bottom: 0.5em;

    ${vars.breakpoint.lg`
      margin-bottom: 0.3em;
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MediumList = styled(BigList)`
  ${vars.breakpoint.lg`
    font-size: 60px;
  `}

  li {
    ${vars.breakpoint.lg`
      margin-bottom: 0.4em;
    `}
  }
`;

const SubHeading = styled.h4`
  font-size: 30px;

  ${vars.breakpoint.lg`
    font-size: 40px;
  `}
`;

export default About;
