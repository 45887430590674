import styled from 'styled-components';
import typography from '../../styles/typography';

const BodyLarge = styled.div`
  p {
    ${typography.bodyLarge};
  }
`;

export default BodyLarge;
