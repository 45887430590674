import React from 'react';
import DividerHeader from '../divider-header';
import Container from '../container';
import SectionHeader from '../section-header';
import FancyLink from '../fancy-link';
import styled from 'styled-components';
import * as vars from '../../styles/vars';
// import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import data from './data';

const About = () => {
  // const data = useStaticQuery(graphql`
  //   query ProjectsQuery {
  //     site {
  //       projects {
  //         title
  //         url
  //       }
  //     }
  //   }
  // `);
  return (
    <>
      <Container id="work">
        <DividerHeader title="Work" data-anim-in />
      </Container>
      <SectionHeader slim lessTopPadding>
        <div data-anim-in>
          <p>
            {' '}
            Here is a tiny sample of recent-ish <em>front-end</em> website work,
            all the web app stuff is typically behind logins so not much point
            in showing it here. I'm happy to discuss that side of things in
            detail over a coffee and/or a pastry.
          </p>
        </div>
      </SectionHeader>

      <Container slim>
        {/* <h4>Front-end:</h4> */}

        <ProjectList>
          {_.map(data, (item, index) => {
            return (
              <li key={_.kebabCase(`${item}-${index}`)} data-anim-in>
                <FancyLink
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  distanceThreshold={{ min: 0, max: 200 }}
                  data-hover
                >
                  {item.title}
                </FancyLink>
              </li>
            );
          })}
        </ProjectList>

        <SubHeading data-anim-in as="h3">
          Design, front-end and back-end:
        </SubHeading>

        <ProjectList>
          <li data-anim-in>
            <FancyLink
              href="https://www.embersyork.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              distanceThreshold={{ min: 0, max: 40 }}
              data-hover
            >
              Embers
            </FancyLink>
          </li>
          <li data-anim-in>
            <FancyLink
              href="https://www.shambleskitchen.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              distanceThreshold={{ min: 0, max: 40 }}
              data-hover
            >
              Shambles Kitchen
            </FancyLink>
            <p>
              Two sites I planned, designed and built with a Craft CMS powered
              backend.
            </p>
          </li>
        </ProjectList>

        {/* <BodyLarge>
          <p>
            When I'm not using Styled Components, I love to write my CSS using the BEMIT methodology. It keeps things
          </p>
        </BodyLarge> */}
      </Container>
    </>
  );
};

const ProjectList = styled.ul`
  color: ${vars.colors.brand};
  list-style: none;
  margin: 0 0 120px;

  li {
    margin-bottom: 0.8em;

    ${vars.browserHack.firefox`
       margin-bottom: 1.1em;
    `}

    ${vars.browserHack.edge`
       margin-bottom: 1.1em;
    `}

    ${vars.breakpoint.lg`
      margin-bottom: .6em;

      ${vars.browserHack.firefox`
       margin-bottom: 1.5em;
    `}

     ${vars.browserHack.edge`
       margin-bottom: 1.1em;
    `}
    `}

    a {
      font-size: 38px;
      color: ${vars.colors.black};
      border-bottom: 4px solid #f3f3f3;
      text-shadow: -2px -2px #fff, -2px 2px #fff, 2px -2px #fff, 2px 2px #fff;
      line-height: 1.4;
      display: inline;


      ${vars.browserHack.firefox`
       line-height: 1.1;
      `}

      ${vars.browserHack.edge`
       line-height: 1.1;
      `}

      ${vars.breakpoint.md`
        display: inline-block;
      `}

      ${vars.breakpoint.lg`
        font-size: 50px;
      `}

      &:hover {
        color: ${vars.colors.brand};

        span {
          background-color: ${vars.colors.brand};
        }
      }

      span {
        transition: background-color 0.3s ease-in-out;
        height: 4px;
        bottom: -4px;
        background-color: ${vars.colors.greyLighter};
      }
    }
  }

  p {
    color: ${vars.colors.monsoon};
    margin-top: 1em;
    font-size: 22px;
  }
`;

const SubHeading = styled.h4`
  font-size: 28px;

  ${vars.breakpoint.lg`
    font-size: 40px;
  `}
`;

export default About;
