import React from 'react';
import styled from 'styled-components';
import * as vars from '../../styles/vars';

const Container = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};

const StyledContainer = styled.div`
  max-width: ${vars.global.siteWidth};
  margin: 0 auto;
  padding: 0 24px;

  ${vars.breakpoint.md`
    padding: 0 40px;
  `}

  ${vars.breakpoint.lg`
     padding: 0 50px;
  `}

  ${props =>
    props.slim &&
    `
    max-width: 1020px;
  `};

  ${props =>
    props.relative &&
    `
    position: relative;
  `};
`;

export default Container;
