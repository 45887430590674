import React from 'react';
import styled from 'styled-components';
import * as vars from '../../styles/vars';

const DividerHeader = ({ title, ...props }) => {
  return (
    <Wrapper {...props}>
      <h2 {...props}>{title}</h2>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 80px;
  padding-top: 30px;
  position: relative;

  ${vars.breakpoint.lg`
    margin-top: 150px;
  `}

  h2 {
    color: ${vars.colors.brand};
    font-family: ${vars.global.headingFontFamily};
    font-size: 22px;
    text-transform: uppercase;

    ${vars.breakpoint.sm`
      font-size: 26px;
    `}
  }

  &:before {
    background-color: ${vars.colors.brand};
    content: '';
    display: block;
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    ${vars.breakpoint.sm`
      border-radius: 5px;
      height: 6px;
    `}
  }
`;

export default DividerHeader;
