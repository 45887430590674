import React from 'react';
import styled from 'styled-components';
import * as vars from '../../styles/vars';
import Container from '../container';
import FancyLink from '../fancy-link';

const Contact = () => {
  // const getCurrentDate = () => {
  //   return new Date().getFullYear();
  // };

  return (
    <>
      <Wrapper id="contact">
        <Inner>
          <Container slim>
            <Heading data-anim-in>Get in touch</Heading>
            <div data-anim-in>
              <PostHeading>
                <p>
                  If you think I might be able to help you then feel free to hit
                  me up. I'm always keen to explore new working relationships.
                </p>
              </PostHeading>
            </div>

            <Email data-anim-in>
              <FancyLink data-hover isEmail></FancyLink>
            </Email>
            {/* <LinkedIn data-anim-in>
              <span>Or find me on</span>{' '}
              <a href="http://linkedin.com.au/mikeebee" data-hover>
                LinkedIn
              </a>
            </LinkedIn> */}
          </Container>
        </Inner>
        <Blob
          className="morph"
          viewBox="0 0 1364 715"
          preserveAspectRatio="none"
        >
          <path d="M49.2809772,189.776748 C-22.9691557,283.567852 -19.5482214,446.502631 84.7218568,518.809028 C198.844226,597.98617 438.708169,593.690569 587.997742,613.866563 C766.296839,637.968695 866.747443,703.228513 1040.53091,663.421824 C1179.69451,631.576472 1287.79604,497.869619 1294.63791,382.811927 C1301.47978,256.848293 1189.27313,120.960252 1051.20422,57.2695487 C855.526778,-33.936847 591.020136,0.83129713 371.122478,44.727715 C251.526614,68.6117287 130.972889,83.5528697 49.2809772,189.776748 Z" />
        </Blob>
      </Wrapper>
      <Copy>
        <Container slim>
          Find me on{' '}
          <FancyLink href="https://www.linkedin.com/in/mikeebee/" data-hover>
            LinkedIn
          </FancyLink>
        </Container>
      </Copy>
    </>
  );
};

const Wrapper = styled.div`
  color: #fff;
  margin: 100px 0;
  padding-top: 100px;
  position: relative;

  ${vars.breakpoint.sm`
    padding-top: 120px;
  `}

  ${vars.breakpoint.md`
    padding-top: 150px;
  `}

  ${vars.breakpoint.lg`
    margin: 200px 0;
    padding-top: 240px;
  `}
`;

const Inner = styled.div`
  position: relative;
  z-index: 1;
`;

const Heading = styled.h2`
  color: #fff;
  font-family: ${vars.global.headingFontFamily};
  font-size: 54px;
  margin-bottom: 10px;

  ${vars.breakpoint.xxs`
    font-size: 45px;
  `}

  ${vars.breakpoint.md`
    font-size: 60px;
  `}

  ${vars.breakpoint.lg`
    font-size: 78px;
  `}
`;

const PostHeading = styled.div`
  font-size: 24px;
  line-height: 1.2;
  max-width: 640px;
  opacity: 0.7;

  ${vars.breakpoint.lg`
    font-size: 30px;
  `}
`;

const Email = styled.div`
  font-size: 33px;
  margin-top: 60px;

  ${vars.breakpoint.xxs`
    font-size: 24px;
  `}

  ${vars.breakpoint.xs`
    font-size: 33px;
  `}

  ${vars.breakpoint.sm`
    font-size: 56px;
  `}

  ${vars.breakpoint.lg`
    font-size: 78px;
    margin-top: 80px;
  `}

  a {
    border-bottom-width: 4px;

    ${vars.breakpoint.md`
      border-bottom-width: 6px;
  `}
  }


  span {
    bottom: -4px;
    height: 4px;

    ${vars.breakpoint.md`
      height: 6px;
      bottom: -6px;
    `}
  }
`;

const Copy = styled.div`
  color: ${vars.colors.brand};
  font-size: 18px;
  margin-bottom: 10px;
  margin-right: 100px;
  padding: 100px 0 30px;
  position: relative;
  z-index: 10;

  ${vars.breakpoint.md`
    margin-bottom: 100px;
    font-size: 20px;
    padding: 60px 0 30px;
  `}

  a {
    color: ${vars.colors.brand};
    text-decoration: none;
    border-bottom: 1px solid ${vars.colors.brand};
    display: inline-block;
    line-height: 1.2;

    span {
      height: 2px;
      bottom: -1px;
    }
  }
`;

const Blob = styled.svg`
  width: 1100px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, 0, 0);

  ${vars.breakpoint.md`
    width: 1200px;
  `}

  ${vars.breakpoint.lg`
    width: 1800px;
  `}
`;

export default Contact;
