import React from 'react';

const Chevron = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 66">
      <path
        stroke="#9134FC"
        strokeWidth="6"
        d="M33 3L3 33l30 30"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chevron;
