const data = [
  { title: 'Sovereign Hill', url: 'https://sovereignhill.com.au/' },

  {
    title: 'The Australian Ballet',
    url: 'https://australianballet.com.au/',
  },

  {
    title: 'Envato',
    url: 'https://envato.com/',
  },

  {
    title: 'Powershop',
    url: 'https://www.powershop.com.au/',
  },

  {
    title: 'Bonds',
    url: 'https://www.bonds.com.au/',
  },

  {
    title: 'RACV',
    url: 'https://www.racv.com.au/',
  },

  {
    title: 'The Walter & Eliza Hall Institute',
    url: 'https://discovery.wehi.edu.au/',
  },

  // {
  //   title: 'English Tapware',
  //   url: 'https://www.englishtapware.com.au/',
  // },
];

export default data;
