import React from 'react';
import styled from 'styled-components';
import Container from '../container';
import * as vars from '../../styles/vars';

const SectionHeader = ({ children, slim, ...props }) => {
  return (
    <Wrapper {...props}>
      <Container slim={slim}>{children}</Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 80px 0;

  ${vars.breakpoint.lg`
    padding: 150px 0;

     ${props =>
       props.lessTopPadding &&
       `
        padding: 100px 0 150px;
      `};
    `}

  ${props =>
    props.zeroBottomPadding &&
    `
      padding-bottom: 0 !important;
    `};

  p {
    color: ${vars.colors.monsoon};
    line-height: 1.2;
    margin-top: -20px;
    max-width: 900px;
    font-size: 24px;

    ${vars.breakpoint.lg`
      font-size: 30px;
    `}

    em {
      font-style: normal;
      color: ${vars.colors.grey};
    }

    b {
      font-style: normal;
      font-weight: normal;
      color: ${vars.colors.greyLight};
      text-decoration: line-through;
    }
  }
`;

export default SectionHeader;
