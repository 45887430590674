import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import FlickityComponent from 'react-flickity-component';
import anime from 'animejs';
import Segment from 'segment-js';
import ScrollMagic from 'scrollmagic';
import Container from '../container';
import SectionHeader from '../section-header';
import VisuallyHidden from '../visually-hidden';
import { H1 } from '../type';
import { Chevron } from '../icons';
import { TimelineMax } from 'gsap/all';
import { data } from './data';
import * as vars from '../../styles/vars';

export default class Testimonials extends Component {
  constructor() {
    super();

    this.state = {
      selectedIndex: 0,
    };

    this.loaderCircle = React.createRef();
    this.loaderSvg = React.createRef();
    this.loaderWrapper = React.createRef();
    this.paginationList = React.createRef();
    this.navButtons = React.createRef();

    this.loaderCircleAnim = null;
    this.onFirstSlide = true;
    this.onLastSlide = false;
    this.previousIndex = null;
  }

  addFlickityResizeHack = () => {
    // https://codepen.io/desandro/pen/ZXEGVq
    const resize = this.flkty.resize;

    this.flkty.resize = () => {
      this.flkty.element.classList.remove(`flickity-resize`);
      resize.call(this.flkty);
      this.flkty.element.classList.add(`flickity-resize`);
    };
  };

  componentDidMount = () => {
    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 'onEnter',
      },
    });

    this.setUpLoader();
    this.animatePaginationItemsUp();

    this.previousIndex = this.flkty.selectedIndex;

    // You can register events in componentDidMount hook
    this.flkty.on('change', index => {
      this.runCircleAnimationBasedOnDirection(index);

      this.setState({
        selectedIndex: this.flkty.selectedIndex,
      });

      if (this.flkty.selectedIndex === 0) {
        this.onFirstSlide = true;
      } else {
        this.onFirstSlide = false;
      }

      if (this.flkty.selectedIndex === data.length - 1) {
        this.onLastSlide = true;
      } else {
        this.onLastSlide = false;
      }
    });

    this.addFlickityResizeHack();
  };

  runCircleAnimationBasedOnDirection = index => {
    // Get changed index
    const delta = index - this.previousIndex;

    if (delta > 0) {
      this.loaderCircleAnimReverse.play();
    } else {
      this.loaderCircleAnim.play();
    }

    this.previousIndex = index;
  };

  setUpLoader = () => {
    this.segment = new Segment(this.loaderCircle.current, 0, '100%');

    this.loaderCircleAnim = anime({
      targets: this.loaderCircle.current,
      autoplay: false,
      keyframes: [
        {
          strokeDasharray: this.segment.strokeDasharray('100%', '100%'),
          duration: 700,
        },
        {
          strokeDasharray: this.segment.strokeDasharray(0, '100%'),
          duration: 0,
        },
      ],
      easing: 'easeInOutQuad',
      loop: false,
      complete: () => {
        // anime.set(this.loaderSvg.current, {
        //   opacity: 0,
        //   scale: 0.5,
        // });
        this.loaderSvgAnim.play();
      },
    });

    this.loaderCircleAnimReverse = anime({
      targets: this.loaderCircle.current,
      autoplay: false,
      keyframes: [
        {
          strokeDasharray: this.segment.strokeDasharray(0, 0),
          duration: 700,
        },
        {
          strokeDasharray: this.segment.strokeDasharray(0, '100%'),
          duration: 0,
        },
      ],
      easing: 'easeInOutQuad',
      loop: false,
      complete: () => {
        anime.set(this.loaderSvg.current, {
          opacity: 0,
          scale: 0.5,
        });
        this.loaderSvgAnim.play();
      },
    });

    this.loaderSvgAnim = anime.timeline({
      targets: this.loaderSvg.current,
      autoplay: false,
      easing: 'linear',
      loop: false,
    });

    this.loaderSvgAnim.add({
      scale: [0.5, 1],
      opacity: [0, 1],
      easing: 'easeOutElastic(1, .4)',
      duration: 1000,
    });

    anime.set(this.loaderSvg.current, {
      opacity: 1,
      scale: 1,
    });

    // Need to set the circle to 0 0 so we can animate it with scrollmagic
    anime.set(this.loaderCircle.current, {
      strokeDasharray: this.segment.strokeDasharray(0, 0),
    });
  };

  handleNextClick = () => {
    if (this.state.selectedIndex === data.length - 1) return;

    this.flkty.next();
  };

  handlePrevClick = () => {
    if (this.state.selectedIndex === 0) return;

    this.flkty.previous();
  };

  handleClick = index => {
    this.flkty.select(index);
  };

  getTabIndex = index => {
    return index === this.state.selectedIndex ? '' : '-1';
  };

  animatePaginationItemsUp = () => {
    // Pagination items
    let paginationItemsAnim = new TimelineMax().staggerFrom(
      this.paginationList.current.querySelectorAll('li'),
      1,
      {
        opacity: 0,
        y: 8,
        scale: 0.98,
      },
      0.5
    );

    new ScrollMagic.Scene({
      duration: 200,
      triggerElement: this.paginationList.current,
    })
      .setTween(paginationItemsAnim)
      .offset(100)
      .addTo(this.controller);

    // Nav buttons
    let navButtonsAnim = new TimelineMax()
      .from(
        this.navButtons.current.querySelectorAll('li:first-child'),
        1,
        {
          opacity: 0,
          x: -10,
          scale: 0.98,
        },
        0.5
      )
      .from(
        this.navButtons.current.querySelectorAll('li:last-child'),
        1,
        {
          opacity: 0,
          x: 10,
          scale: 0.98,
        },
        0.5
      );

    new ScrollMagic.Scene({
      duration: 200,
      triggerElement: this.navButtons.current,
    })
      .setTween(navButtonsAnim)
      .offset(300)
      .addTo(this.controller);

    // Circle
    let tl = anime.timeline({
      targets: this.loaderCircle.current,
      easing: 'easeInOutCubic',
      autoplay: false,
    });

    tl.add({
      strokeDasharray: this.segment.strokeDasharray(0, '100%'),
    });

    new ScrollMagic.Scene({
      duration: 200,
      triggerElement: this.loaderWrapper.current,
    })
      .on('progress', function(event) {
        tl.seek(tl.duration * event.progress);
      })
      .offset(100)
      .addTo(this.controller);
  };

  render() {
    return (
      <Wrapper id="testimonials">
        <SectionHeader slim>
          <div data-anim-in>
            <H1 as="h2">Nice things people have said about me</H1>
            <p>
              Let’s face it, I could drivel on about how good I am, but what
              really counts is the words from people I’ve worked with.
            </p>
          </div>
        </SectionHeader>
        <Container slim relative>
          <Nav>
            <Pagination>
              <PaginationList
                selectedIndex={this.state.selectedIndex}
                ref={this.paginationList}
              >
                {_.map([...Array(data.length)], (item, index) => (
                  <li key={`pagination-${index}`}>
                    <PaginationItem
                      onClick={() => this.handleClick(index)}
                      isCurrent={index === this.state.selectedIndex}
                      data-hover
                    >
                      {index + 1}
                    </PaginationItem>
                  </li>
                ))}
              </PaginationList>

              <LoadingWrapper ref={this.loaderWrapper}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 60 60"
                  ref={this.loaderSvg}
                >
                  <path
                    d="M2,30a28,28 0 1,0 56,0a28,28 0 1,0 -56,0"
                    stroke="#9134FC"
                    strokeWidth="4"
                    fill="none"
                    fillRule="evenodd"
                    ref={this.loaderCircle}
                  />
                </svg>
              </LoadingWrapper>
            </Pagination>

            <NavButtons ref={this.navButtons}>
              <li>
                <PrevButton
                  onClick={this.handlePrevClick}
                  disabled={this.onFirstSlide}
                  data-hover
                >
                  <VisuallyHidden>Previous testimonial</VisuallyHidden>
                  <Chevron />
                </PrevButton>
              </li>
              <li>
                <NextButton
                  onClick={this.handleNextClick}
                  disabled={this.onLastSlide}
                  data-hover
                >
                  <VisuallyHidden>Next testimonial</VisuallyHidden>
                  <Chevron />
                </NextButton>
              </li>
            </NavButtons>
          </Nav>
          <FlickityWrapper data-anim-in>
            <FlickityComponent
              flickityRef={c => (this.flkty = c)}
              options={{
                pageDots: false,
                prevNextButtons: false,
                adaptiveHeight: false,
                arrowShape: 'M33 3L3 33l30 30',
              }}
            >
              {_.map(data, (item, index) => {
                return (
                  <Cell key={`${_.kebabCase(item.who)}-${index}`}>
                    <Blockquote data-hover>
                      <Quote
                        dangerouslySetInnerHTML={{
                          __html: item.quote,
                        }}
                      />
                      <Cite>
                        <a href={item.url} tabIndex={this.getTabIndex(index)}>
                          <h2 data-hover>{item.who}</h2>
                          <p>{item.job}</p>
                        </a>
                      </Cite>
                    </Blockquote>
                  </Cell>
                );
              })}
            </FlickityComponent>
          </FlickityWrapper>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  padding: 0 0 20px 0;

  ${vars.breakpoint.lg`
    padding: 0 0 40px 0;
  `}

  ${vars.breakpoint.xl`
    padding: 0 0 80px 0;
  `}
`;

const Blockquote = styled.blockquote`
  color: ${vars.colors.cod};
  filter: blur(3px);
  margin: 0;
  opacity: 0.08;
  padding: 0;
  transition: all 0.4s ease-in-out;
`;

const Cell = styled.div`
  width: 100%;
  margin: 0 100px;

  &.is-selected {
    ${Blockquote} {
      opacity: 1;
      filter: blur(0);
    }
  }
`;

const FlickityWrapper = styled.div`
  .flickity-resize {
    ${Cell} {
      min-height: 100%;
    }
  }
`;

const Nav = styled.div`
  margin-bottom: 50px;
  display: flex;
  align-items: center;
`;

const NavButtons = styled.ul`
  margin-left: auto;
  list-style: none;
  display: flex;
  align-items: center;

  svg {
    transition: transform 0.2s ease-in-out;
  }
`;

const PrevButton = styled.button`
  margin-right: 15px;
  transition: opacity 0.3s ease-in-out;
  width: 18px;

  ${vars.breakpoint.sm`
    width: 22px;
  `}

  &:hover {
    svg {
      transform: translateX(-3px);
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const NextButton = styled.button`
  margin-left: 15px;
  transition: opacity 0.3s ease-in-out;
  width: 18px;

  svg {
    transform: rotate(180deg);
  }

  &:hover {
    svg {
      transform: rotate(180deg) translateX(-3px);
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${vars.breakpoint.sm`
    width: 22px;
  `}
`;

const Pagination = styled.nav`
  height: 80px;
  left: -50px;
  overflow: hidden;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  width: 350px;

  ${vars.breakpoint.sm`
    left: -60px;
  `}

  &:before,
  &:after {
    content: '';
    display: block;
    height: 60px;
    left: 0;
    position: absolute;
    width: 60px;
    z-index: 1;
    top: 0;
  }

  &:before {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 29%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 0;
  }

  &:after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    left: auto;
    right: 0;
    width: 90px;

    ${vars.breakpoint.sm`
       width: 60px;
    `}
  }
`;

const PaginationList = styled.ol`
  display: flex;
  height: 60px;
  left: 60px;
  position: relative;
  transition: all 0.6s ease-in-out;

  ${props =>
    `
     left: ${50 - props.selectedIndex * 50}px;

    `};

  ${vars.breakpoint.sm`
    ${props =>
      `
       left: ${60 - props.selectedIndex * 60}px;
    `};
    `}
`;

const PaginationItem = styled.button`
  font-size: 26px;
  line-height: 60px;
  text-align: center;
  width: 50px;
  transition: color 0.3s ease-in-out 0.4s;

  ${vars.breakpoint.sm`
    width: 60px;
    font-size: 30px;
    line-height: 58px;
  `}

  ${vars.browserHack.firefox`
    line-height: 64px;
  `}

    ${vars.browserHack.edge`
    line-height: 64px;
  `}

  &:hover {
    color: ${vars.colors.brand};
  }

  ${props =>
    props.isCurrent &&
    `
  color: ${vars.colors.brand}
`};
`;

const LoadingWrapper = styled.div`
  height: 60px;
  left: 70px;
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translateX(-50%) translateY(-50%) rotate(90deg) scale(1);
  width: 50px;

  ${vars.breakpoint.sm`
    left: 90px;
    width: 60px;
  `}
`;

const Quote = styled.div`
  font-family: ${vars.global.serifFontFamily};
  font-size: 20px;
  line-height: 1.4;

  ${vars.breakpoint.sm`
    font-size: 22px;
  `}

  ${vars.breakpoint.lg`
    font-size: 28px;
  `}
`;

const Cite = styled.footer`
  margin-top: 60px;

  h2 {
    font-size: 24px;
    margin-bottom: 0.1em;
    transition: color 0.3s ease-in-out;

    ${vars.breakpoint.lg`
      font-size: 30px;
    `}
  }

  p {
    color: ${vars.colors.monsoon};
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 0;

    ${vars.breakpoint.lg`
      font-size: 26px;
    `}
  }

  a {
    text-decoration: none;
    display: inline-block;

    &:hover {
      h2 {
        color: ${vars.colors.brand};
      }
    }
  }
`;
