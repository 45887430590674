import styled from 'styled-components';
import * as vars from '../../styles/vars';

const H1 = styled.h1`
  font-size: 34px;

  span {
    color: ${vars.colors.monsoon};
    display: block;
    font-size: 26px;
    margin-top: 0.1em;

    ${vars.breakpoint.lg`
      font-size: 42px;
    `}
  }

  ${vars.breakpoint.lg`
    font-size: 50px;
  `}
`;

export default H1;
