import React from 'react';
import styled from 'styled-components';
import * as vars from '../../styles/vars';

const StrikeThrough = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.span`
  display: inline-block;
  color: ${vars.colors.greyLighter};
  position: relative;

  &:before {
    background-color: ${vars.colors.greyLight};
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(calc(-50% + 5px)) rotate(-3deg);
    height: 1px;
  }
`;

export default StrikeThrough;
