export const data = [
  {
    who: 'Daniel Ogden',
    job: 'Senior front-end web developer',
    quote: `<p>There are no developers that I respect more than Mike, he's got legitimate experience and skill in all of his work, instincts that are laser sharp, and most importantly is an intensely pleasant person to collaborate with.</p>

  <p>I had the pleasure of working on his team at Carter, being upskilled and mentored by him in both the technical side of front-end development, and the user experience side; developing skills I now use everyday to add extra magic to the work I deliver.</p>

  <p>If you haven't already, just take a look at the quality of any piece of work he delivers, that speaks to his skill and his talent more than anything else.</p>`,
    url: 'https://www.linkedin.com/in/danielogdenweb/',
  },

  {
    who: 'Jay Healey',
    job: 'Product developer',
    quote: `
          <p>
            I worked closely with Mike for a long period on several
            projects, large and small, and the results are always the
            same: top-notch. He constantly pushes for the best, and always
            adds a level of polish rarely seen whilst crafting quality
            code. Plus, his friendly and thoughtful approach gets the best
            out of those around him and always has an eye on what the
            client wants. With those skills in play, he took naturally to
            team leadership, and continues to run the friendliest
            front-end development team I've worked with.
          </p>
          <p>
            The best thing I can say about Mike is that I hope work him
            again in future.
          </p>`,
    url: 'https://www.linkedin.com/in/jayhealey/',
  },

  {
    who: 'Maddi Skeggs',
    job: 'UX & UI Designer - Inlight',
    quote: `<p>Having Mike on my projects was genuinely something I looked forward to. Beyond his exceptional development skills, he brings a level of enthusiasm and joy to his team on a daily basis. His feedback and insights always add value and power the conversation up, helping us find better ideas, better designs and better outcomes.</p> <p>He has an eye for detail as well as a vision for the bigger picture. An absolute gem to work alongside, could not recommend enough.</p>`,
    url: 'https://www.linkedin.com/in/maddi-skeggs-41526591/',
  },

  {
    who: 'Dillon Bailey',
    job: 'Director - Honest Fox',
    quote: `<p>
            The phrase "absolute gun" is thrown around a lot in the
            developer community these days, but Mike is truly one of the
            few worthy of the title. Mike and I have worked together as
            team members, development lead and subordinate, agency
            director and freelancer. In all occasions and in all
            circumstances, I can confidently say Mike was a supporting
            colleague, a brilliant lead and well-oiled machine of a
            freelancer.
          </p>
          <p>
            In any circumstance, Mike is a man you can trust to deliver an
            honest and complete result.
          </p>`,
    url: 'https://www.linkedin.com/in/dillonbailey/',
  },
  {
    who: 'Felicity de Lang',
    job: 'Performance and Culture Lead - Inlight',
    quote: `<p>Mike has worked with us a couple of times, the most recent engagement extended half a dozen times as we got very used to the projects he was leading being so reliably delivered. He's a safe set of hands and delightfully easy to work with. Of course, he's an excellent developer and produces high quality work but he also understands what's needed to successfully delivery projects - for our business and our clients.</p> <p>He's not a transactional contractor, he comfortably occupies a place in the team and contributes to our culture. We'll certainly miss him.</p>`,
    url: 'https://www.linkedin.com/in/felicity-de-lang-4424a131/',
  },
  {
    who: 'Sasha Dobson',
    job: 'Senior Producer - WOW Pictures',
    quote: `<p>Mike is undoubtedly one of the best developers I have worked with. He possesses an incredibly rare combination of impeccable technical capability while also being an excellent communicator. In his role as a contractor he goes above and beyond, routinely informing and outlining project stakeholders of the risks and opportunities beyond the scope of his contract.</p>
<p>He has fantastic attention to detail in both the planning phases as well as throughout the delivery of his work.
On top of all this, he's an absolute joy to be around. Fantastic attitude and work ethic (and occasionally funny).</p>`,
    url: 'https://www.linkedin.com/in/sashalouisedobson/',
  },
  //   {
  //     who: 'Paul Lowton',
  //     job: 'Netstep - Former Head of Development',
  //     quote: `<p>Mike is one of those people who you can give a job to in the full knowledge that what you’ll receive back is of the highest standard, has been thoroughly considered, and is completely up-to-date with the latest thinking and technologies.</p>

  // <p>I have never worked with anyone with a better approach to work, always completely professional but with a cheeky smile, and always vigorously churning out work of the highest quality. What I like most about Mike is that simple things stay simple whilst the complicated appears to get simplified. In an industry where things can easily get overwhelming, this is a really important skill.</p>

  // <p>Mike is a joy to work with, and by virtue of his skills and approach that I saw during my time working with him, he made my life simpler and has taught me some great lessons.</p>`,
  //   },
  {
    who: 'Dave Foy',
    job: 'Director - Web Design Build',
    quote: `<p>I first began working with Mike in the early stages of his web development career and was instantly impressed by his keen desire to learn and improve in all areas of expertise. I had no hesitation in hiring him for every job from there on because his broad range of skills were a huge asset to any web project.</p>

<p>Mike quickly developed into a mature web designer and developer, with a keen eye for detail and a solid skill set. His design work is imaginative and full of flair, his development work always informed by the very latest techniques.</p>

<p>His ability to maintain a cool head and a sense of humour, while consistently keeping client satisfaction at the core of everything he does, will make him an essential component in any web team he is part of. He comes with the highest possible recommendation from me.</p>`,
    url: 'https://www.linkedin.com/in/dave-foy-7b41abb9/',
  },
  {
    who: 'Peter Pham',
    job: 'Senior Frontend Developer - MECCA Brands',
    quote: `<p>Mike is one of the best developers I’ve ever worked with and would be a great addition to any team.</p>

<p>He has great instincts when it comes to UX & UI and is someone you can trust to get the job done right. He is exceptional at communicating and has vast experience managing small and large projects.</p>

<p>But aside from his skillset, Mike is just a great person to work with; he makes coming to work everyday a delight.</p>

<p>What more can I say? He’s a top bloke!</p>`,
    url: 'https://www.linkedin.com/in/peterrpham/',
  },
  //  {
  //    who: 'Simon Long',
  //    job: 'Founder - Shambles Kitchen',
  //    quote: `<p>I can’t thank Mikee enough for his efforts in producing a website that perfectly fits the functional needs, budget and visible feeling of our brand. He was very clear in explaining the different options available to me, and in helping me clarify want we wanted to achieve through the website. I’m always being asked who built our website from people that have been really impressed by it. I am frequently suggesting Mikee to people looking to start their own site.</p>`,
  //  },
];
