import React, { Component } from 'react';
import styled from 'styled-components';
import anime from 'animejs';
import Container from '../container';
import * as vars from '../../styles/vars';
const Splitting = typeof document !== `undefined` ? require('splitting') : null;

const initialBlobPath =
  'M49.2809772,189.776748 C-22.9691557,283.567852 -19.5482214,446.502631 84.7218568,518.809028 C198.844226,597.98617 438.708169,593.690569 587.997742,613.866563 C766.296839,637.968695 866.747443,703.228513 1040.53091,663.421824 C1179.69451,631.576472 1287.79604,497.869619 1294.63791,382.811927 C1301.47978,256.848293 1189.27313,120.960252 1051.20422,57.2695487 C855.526778,-33.936847 591.020136,0.83129713 371.122478,44.727715 C251.526614,68.6117287 130.972889,83.5528697 49.2809772,189.776748 Z';

export default class Hero extends Component {
  constructor(props) {
    super(props);

    this.introRef = React.createRef();
    this.shapeRef = React.createRef();
    this.heroHeadingRef = React.createRef();
    this.heroPostHeadingRef = React.createRef();

    this.shapePathData = [
      // {
      //   value: initialBlobPath,
      //   duration: 100,
      // },
      {
        value:
          'M49.2809772,205.776748 C-22.9691557,299.567852 -19.5482214,462.502631 84.7218568,534.809028 C198.844226,613.98617 438.708169,609.690569 587.997742,629.866563 C766.296839,653.968695 866.747443,719.228513 1040.53091,679.421824 C1179.69451,647.576472 1287.79604,513.869619 1294.63791,398.811927 C1301.47978,272.848293 1225.68938,112.231263 1087.62047,48.5405598 C891.943024,-42.6658359 591.020136,16.8312971 371.122478,60.727715 C251.526614,84.6117287 130.972889,99.5528697 49.2809772,205.776748 Z',
        duration: 1400,
        delay: 1000,
      },
      {
        value:
          'M58.3905931,185.836561 C-14.1421348,279.627666 -28.2265285,452.409349 76.4513856,524.715746 C191.020126,603.892888 426.582725,611.737661 576.456222,631.913654 C755.452707,656.015787 845.498102,680.014186 1019.96129,640.207496 C1159.66922,608.362145 1271.05148,507.868825 1277.92011,392.811133 C1284.78874,266.847499 1182.05728,139.188629 1043.44833,75.4979261 C847.005529,-15.7084696 594.539309,-14.7580597 373.781556,29.1383582 C253.717912,53.0223719 140.40203,79.6126831 58.3905931,185.836561 Z',
        duration: 1400,
        delay: 1000,
      },
      // {
      //   value:
      //     'M49.2809772,206.776748 C-22.9691557,300.567852 -19.5482214,463.502631 84.7218568,535.809028 C198.844226,614.98617 488.997548,631.906654 638.287121,652.082648 C816.586218,676.18478 917.036821,741.444598 1090.82029,701.637908 C1229.98389,669.792557 1314.29201,512.949352 1321.13388,397.89166 C1327.97574,271.928026 1252.18534,111.310996 1114.11643,47.6202928 C918.438991,-43.586103 591.020136,17.8312971 371.122478,61.727715 C251.526614,85.6117287 130.972889,100.55287 49.2809772,206.776748 Z',
      //   duration: 1400,
      //   delay: 1000,
      // },
      // {
      //   value:
      //     'M49.5337272,223.752838 C-22.7164056,317.543942 -19.2954713,480.47872 84.9746068,552.785118 C199.096976,631.962259 530.997548,631.906654 680.287121,652.082648 C858.586218,676.18478 959.036821,741.444598 1132.82029,701.637908 C1271.98389,669.792557 1356.29201,512.949352 1363.13388,397.89166 C1369.97574,271.928026 1294.18534,111.310996 1156.11643,47.6202928 C960.438991,-43.586103 633.020136,17.8312971 413.122478,61.727715 C293.526614,85.6117287 131.225639,117.528959 49.5337272,223.752838 Z',
      //   duration: 1400,
      //   delay: 1000,
      // },
      {
        value:
          'M49.5337272,218.752838 C-22.7164056,312.543942 -19.2954713,475.47872 84.9746068,547.785118 C199.096976,626.962259 530.997548,626.906654 680.287121,647.082648 C858.586218,671.18478 959.036821,736.444598 1132.82029,696.637908 C1271.98389,664.792557 1322.56239,496.857649 1329.40426,381.799957 C1336.24613,255.836323 1294.18534,106.310996 1156.11643,42.6202928 C960.438991,-48.586103 631.945526,29.2869965 412.047868,73.1834144 C292.452004,97.0674281 131.225639,112.528959 49.5337272,218.752838 Z',
        duration: 1400,
        delay: 1000,
      },
    ];
  }

  componentDidMount() {
    this.splitText();
    this.setBlobAnimation();
  }

  splitText = () => {
    Splitting({ target: this.heroHeadingRef.current });
    Splitting({ target: this.heroPostHeadingRef.current });
    this.setTextAnimation();
  };

  setTextAnimation = () => {
    const textTl = anime.timeline({});

    textTl
      .add({
        targets: this.heroHeadingRef.current.querySelectorAll('.char'),
        duration: 2000,
        easing: 'easeInOutElastic(1, .5)',
        translateX: [-15, 0],
        translateY: '-100%',
        delay: anime.stagger(20),
      })
      .add({
        targets: this.heroPostHeadingRef.current.querySelectorAll('.char'),
        duration: 700,
        easing: 'linear',
        opacity: [0, 1],
        delay: anime.stagger(20),
      });
  };

  setBlobAnimation = () => {
    anime.set('.morph', {
      fill: vars.colors.brand,
    });

    anime({
      targets: this.introRef.current,
      easing: 'easeInOutElastic(1, .5)',
      duration: 3000,
      top: [200, 0],
      delay: 3000,
      complete: () => {
        anime({
          targets: '.morph path',
          easing: 'easeInOutElastic(1, .5)',
          d: this.shapePathData,
          loop: true,
          direction: 'alternate',
        });
      },
    });
  };

  render() {
    return (
      <Wrapper>
        <TitleArea>
          <Container slim>
            <HeroHeading>
              <HeroHeadingInner ref={this.heroHeadingRef}>
                Mike Barker
              </HeroHeadingInner>
            </HeroHeading>
            <PostHeroHeading>
              <PostHeroHeadingInner ref={this.heroPostHeadingRef}>
                Front-end developer
              </PostHeroHeadingInner>
            </PostHeroHeading>
          </Container>
        </TitleArea>
        <Intro ref={this.introRef}>
          <Content>
            <Container slim relative>
              <p>
                I have over 15 years commercial experience building performant,
                polished and accessible digital products with clean, sensible
                code and I love to work with good people on projects that make
                people smile.
              </p>
            </Container>
          </Content>
          <Blob className="morph" viewBox="0 0 1364 715" ref={this.shapeRef}>
            <path d={initialBlobPath} />
          </Blob>
        </Intro>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${vars.breakpoint.xs`
    margin: 0 0 80px;
  `}
`;

const TitleArea = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;

  ${vars.breakpoint.sm`
    height: calc(90vh - 100px);
  `}

  ${vars.breakpoint.md`
    height: 100vh;
  `}

  ${vars.breakpoint.lg`
    height: calc(100vh - 140px);
  `}

  ${vars.breakpoint.landscapeMobile`
    height: 120vh;
  `}

  > div {
    padding-top: 20px;
    width: 100%;

    ${vars.breakpoint.sm`
      padding-top: 0;
    `}

    ${vars.breakpoint.md`
      padding-top: 60px;
    `}

    ${vars.breakpoint.lg`
      padding-top: 140px;
    `}
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;

  p {
    color: #fff;
    font-size: 24px;
    line-height: 1.3;

    ${vars.breakpoint.sm`
      font-size: 30px;
    `}

    ${vars.breakpoint.md`
      padding-right: 30px;
    `}

    ${vars.breakpoint.lg`
      font-size: 40px;
      padding-right: 20px;
    `}
  }
`;

const HeroHeading = styled.h1`
  font-size: 40px;
  margin-bottom: -0.1em;
  padding-top: 0.4em;
  margin-top: -0.4em;
  overflow: hidden;

  ${vars.breakpoint.lg`
    font-size: 50px;
  `}
`;

const HeroHeadingInner = styled.div`
  transform: translateY(100%);

  .char {
    display: inline-block;
  }
`;

const PostHeroHeading = styled.div`
  color: ${vars.colors.monsoon};
  font-size: 32px;
  margin-top: -0.1em;
  overflow: hidden;

  ${vars.breakpoint.lg`
    font-size: 42px;
  `}
`;

const PostHeroHeadingInner = styled.div`
  .char {
    display: inline-block;
  }
`;

const Intro = styled.div`
  padding: 20px 0;
  /* max-width: 1300px; */
  /* min-width: 1000px; */
  position: relative;

  ${vars.breakpoint.sm`
    margin-bottom: 100px;
  `}

  ${vars.breakpoint.md`
    margin-bottom: 140px;
  `}

   ${vars.breakpoint.lg`
    padding: 180px 0 200px;
    margin-bottom: 0;
  `}

`;

const Blob = styled.svg`
  position: absolute;
  width: 860px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  ${vars.breakpoint.md`
    width: 1000px;
  `}

  ${vars.breakpoint.lg`
    width: 1260px;
  `}
`;
